import { SwapPoolTabs } from '../../components/NavigationTabs'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'

import styled from 'styled-components'

import Logo from '../../assets/svg/logo.svg'
import LogoDark from '../../assets/svg/logo_white.svg'
import LogoRect from '../../assets/images/logo-rect.png'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import Settings from '../Settings'
import Menu from '../Menu'
import Network from '../Network'

import { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
// import VersionSwitch from './VersionSwitch'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  border-bottom: .5px solid rgba(255,255,255,0.1);
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme, active }) => 'transparent' || (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img {
      width: 4.5rem;
    }
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

export default function Header() {
  const { account } = useActiveWeb3React()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [isDark] = useDarkModeManager()

  return (
    <HeaderFrame>
      <RowBetween padding={isMobile?"0 1rem" : ".2rem 2rem .2rem 2rem"}>
        <HeaderElement>
          <Title href=".">
            <UniIcon>
              <img
                src={isMobile ? LogoRect : isDark ? LogoDark : Logo}
                alt="logo"
                style={{
                  height: '35px',
                  width: 'auto',
                  display: 'none'
                }}
              />
            </UniIcon>
          </Title>
        </HeaderElement>
        <SwapPoolTabs />
        <HeaderControls>
          <HeaderElement>
            <Network />
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {!isMobile && account && userEthBalance ? (
                <BalanceText
                  style={{
                    flexShrink: 0,
                    border: 'solid 1px gray',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '10px'
                  }}
                  pl="0.75rem"
                  pr="0.5rem"
                  fontWeight={500}
                >
                  {userEthBalance?.toSignificant(4)} {userEthBalance.currency.symbol}
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            {/* <VersionSwitch /> */}
            <Settings />
            <Menu />
          </HeaderElementWrap>
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  )
}
