import { ChainId } from '@sideswap/zkfair-mainnet-sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.OpsideTestnet]: '0xFefEab21A1CddBAda7c1077FBc1cC92e07B5ce78',
  [ChainId.MAINNET]: '0x357fa1565B94D9F7C770D30c95a405F805d95fEA',
  [ChainId.ODXTestnet]: '0x10d146B7c3B8C4195E15F19C71cC917523B7427a',
  [ChainId.ZKFairMainnet]: '0xeed09201d7ADb5BAb2b2edF4681C615d3E678374'//TODO
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
