import { ChainId } from '@sideswap/zkfair-mainnet-sdk'
import { useActiveWeb3React } from '.'
import { ROUTER_ADDRESS_OPSIDE_PUBLIC, ROUTER_ADDRESS_OPSIDE_TESTNET, ROUTER_ADDRESS_ODX_TESTNET,ROUTER_ADDRESS_ZKFIRE_MAINNET } from '../constants'

export function useRouterAddress() {
  const { chainId } = useActiveWeb3React()

  // TODO
  if (ChainId.MAINNET === chainId) return ROUTER_ADDRESS_OPSIDE_PUBLIC
  if (ChainId.OpsideTestnet === chainId) return ROUTER_ADDRESS_OPSIDE_TESTNET
  if (ChainId.ODXTestnet === chainId) return ROUTER_ADDRESS_ODX_TESTNET
  if (ChainId.ZKFairMainnet === chainId) return ROUTER_ADDRESS_ZKFIRE_MAINNET
  return ROUTER_ADDRESS_OPSIDE_TESTNET
}
