import { NavLink, useLocation } from "react-router-dom";
import "./style.css";
import IC_Logo from "./logo.svg";
import IC_Logo_mini from "./logo_mini.svg";
import IC_Link from "./link.svg";
import IC_M_Menu from "./m_menu.svg";
import { ReactNode, useCallback, useEffect, useState } from "react";
import React from "react";
import { isMobile } from 'react-device-detect'
import Tooltip from "../../Tooltip";

type MNUS_ITEM = {
    title: string;
    url?: string;
    external?: boolean;
    navLink?: boolean;
    describe?: string;
    subMenus?: MNUS_ITEM[];
    badge?:true;
    isComingSoon?:boolean;
};

const MENUS: MNUS_ITEM[] = [
    {
        title: "Swap",
        subMenus: [
            {
                title: "Spot Trade",
                describe: "Easy & user-friendly trading interface",
                url: "/swap",
                navLink:true
            },
            {
                title: "Liquidity",
                describe: "Add liquidity acquisition fee",
                url: "/pool",
                navLink:true
            },
        ],
    },
    {
        title: "Perp",
        isComingSoon: false,
        subMenus: [
            {
                title: "Trade",
                describe: "Perpetual contracts with leverage",
                url: "https://perp.sideswap.finance/#/trade",
            },
            {
                title: "Dashboard",
                describe: "Perpetual Statistics",
                url: "https://perp.sideswap.finance/#/dashboard",
            },
            {
                title: "Liquidity",
                describe: "Perpetual Liquidity added",
                url: "https://perp.sideswap.finance/#/liquidity",
            },
            {
                title: "Leaderboard",
                describe: "Perpetual LP and Trader Ranking",
                url: "https://sidev2.sideswap.finance/leaderboard",
                external: false,
            },
        ],
    },
    {
        title: "NFT Club",
        url: "https://sidev2.sideswap.finance/nft",
        badge:true
    },
    {
        title: "Stake",
        url: "https://sidev2.sideswap.finance/stake",
        badge:true
    },
    {
        title: "Bridge",
        url: "https://wallet.zkfair.io/",
        external: true,
    },
    {
        title: "More",
        describe: "swap detailed statistics",
        subMenus: [
            {
                title: "Info",
                external: true,
                describe: "Detailed analysis of swap",
                url: "https://info.sideswap.finance/",
            },
            {
                title: "Doc",
                external: true,
                describe: "Documentation details",
                url: "https://sideswap-1.gitbook.io/sideswap",
            },
        ],
    },
];

const ShareIcon = () => {
    return (
        <div className="linkIcon">
            <img src={IC_Link} alt=""/>
        </div>
    );
};

const Navs = ({ children }: { children?: ReactNode })=>{
    const location = useLocation();
    const customizeActive = useCallback((title: string) => {
        return title === "Swap" ? "activeColor" : "";
        // eslint-disable-next-line
    },[location]);

    const [show, setShow] = useState<boolean>(false)

    const open = useCallback(() => setShow(true), [setShow])
    const close = useCallback(() => setShow(false), [setShow])

    return (
        <div className="navs">
            {children}
            {MENUS.map((item) => (
                <React.Fragment key={item.title}>
                    {item.navLink && item.url ? (
                        <NavLink
                            key={item.title}
                            to={item.url}
                            activeClassName="activeColor"
                        >
                            {item.title} {item.badge && <div className="navBadge"></div>}
                        </NavLink>
                    ) : item.subMenus && !item.isComingSoon ? (
                        <div className="subBox">
                            {
                            // eslint-disable-next-line
                            <a className={customizeActive(item.title)}>{item.title}</a>
                            }
                            <div className="subList">
                                {item.subMenus.map((subItem) => (
                                    <React.Fragment key={subItem.title}>
                                        {subItem.navLink ? (
                                            <NavLink
                                                key={item.title}
                                                to={subItem.url as string}
                                                activeClassName="activeColor">
                                                <span>{subItem.title}</span>
                                                <small>{subItem.describe}</small>
                                            </NavLink>
                                        ) : (
                                            <a
                                                href={subItem.url}
                                                target={subItem.external ? "_blank" : "_self"}
                                            >
                                                <span>
                                                    {subItem.title} {subItem.external && <ShareIcon />}
                                                </span>
                                                <small>{subItem.describe}</small>
                                            </a>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <a href={item.url} target={item.external ? "_blank" : "_self"}>
                            <Tooltip text='Coming Soon' show={show && (item.isComingSoon || false)}>
                                {
                                    item.isComingSoon ? (<span className="ComingSoon" onClick={open} onMouseEnter={open} onMouseLeave={close}>{item.title}</span>) : item.title
                                }
                            </Tooltip>
                            {item.badge && <div className="navBadge"></div>} {item.external && <ShareIcon />}
                        </a>
                    )}
                </React.Fragment>
            ))}
        </div>
    )
}

const NavsMenus = ({ children }: { children?: ReactNode }) => {
    const [toggleMobileNav,setToggleMobileNav] = useState(false);
    const location = useLocation();
    useEffect(()=>{
        setToggleMobileNav(false)
        // eslint-disable-next-line
    },[location])
    return (
        <div className="NavsMenus">
            
            <a href="https://sideswap.finance" className="NavsMenus-logo">
                <img src={isMobile ? IC_Logo_mini : IC_Logo} alt="sideswap" />
            </a>

            {!isMobile && <Navs/>}

            {
                (isMobile && toggleMobileNav) && 
                <Navs>
                    <i className="closeIcon" onClick={()=>setToggleMobileNav(false)}></i>
                </Navs>
            }

            <div style={isMobile ? {display:'flex',alignItems:'center'}:{}}>
                {children}
                {isMobile && <img src={IC_M_Menu} className="menuIcon" alt="menu" onClick={()=>setToggleMobileNav(true)}/>}
            </div>
        </div>
    );
};
export default NavsMenus;
