import { ChainId } from '@sideswap/zkfair-mainnet-sdk'

const SCAN_ADDRESS = {
  [ChainId.MAINNET]: 'https://public.zkevm.opside.info/',
  [ChainId.OpsideTestnet]: 'https://pre-alpha.opside.info',
  [ChainId.ODXTestnet]:'https://odx-zkevm-testnet.zkevm.opside.info',
  [ChainId.ZKFairMainnet]:'https://scan.zkfair.io'
}
export const networkConf = {
  [ChainId.MAINNET]: {
    chainId: '0x2ee8', // 12008
    chainName: 'OpsidePubliczkEVMV2',
    nativeCurrency: {
      name: 'MOZ',
      symbol: 'MOZ',
      decimals: 18
    },
    rpcUrls: ['https://rpc.zkfair.io'],
    blockExplorerUrls: [SCAN_ADDRESS[ChainId.MAINNET]]
  },
  [ChainId.OpsideTestnet]: {
    chainId: '0xc7ea', // 51178
    chainName: 'OpsideTestnet',
    nativeCurrency: {
      name: 'MOZ',
      symbol: 'MOZ',
      decimals: 18
    },
    rpcUrls: ['https://pre-alpha-us-http-geth.opside.network'],
    blockExplorerUrls: [SCAN_ADDRESS[ChainId.OpsideTestnet]]
  },
  [ChainId.ODXTestnet]: {
    chainId: '0x2ef4', // 12020
    chainName: 'ODXzkEVMTestnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://pre-alpha-zkrollup-rpc.opside.network/odx-zkevm-testnet'],
    blockExplorerUrls: [SCAN_ADDRESS[ChainId.ODXTestnet]]
  },
  [ChainId.ZKFairMainnet]: {
    chainId: '0xa70e', // 42766
    chainName: 'ZKFairMainnet',
    nativeCurrency: {
      name: 'USDC',
      symbol: 'USDC',
      decimals: 18
    },
    rpcUrls: ['https://rpc.zkfair.io'],
    blockExplorerUrls: [SCAN_ADDRESS[ChainId.ZKFairMainnet]]
  },
}

export const switchNetwork = async function(chainId: ChainId): Promise<boolean> {
  try {
    const { chainId: chainIdHex } = networkConf[chainId]
    await (window.ethereum as any).request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainIdHex }]
    })
    return true
  } catch (e) {
    if (e.code === 4902) {
      // wallet_addEthereumChain
      const added = await (window.ethereum as any).request({
        method: 'wallet_addEthereumChain',
        params: [{ ...networkConf[chainId] }]
      })
      if (added) {
        return switchNetwork(chainId)
      }
      return false
    }
    console.error(e)
    return false
  }
}
