import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 420px;
  width: 100%;
  background: ${() => 'transparent'};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 1rem;
  border: solid 1px gray;
`

export const SwapWrapper = styled.div<{ mobile?: boolean }>`
  display: flex;
  width: ${props => (props.mobile ? '100%' : '1250px')};
  flex-direction: ${props => (props.mobile ? 'row' : '')};
  flex-wrap: ${props => (props.mobile ? 'wrap-reverse' : '')};
  padding: 0;
  .swaprightBar{
    width: ${props => (props.mobile ? '100%' : '420px')};
    margin-bottom: ${props => (props.mobile ? '20px' : '')};
    flex-shrink: 0;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
