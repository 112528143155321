import { Currency,WETH } from '@sideswap/zkfair-mainnet-sdk'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Field } from '../../state/swap/actions';
import { useV2FactoryContract } from '../../hooks/useContract';
import isZero from '../../utils/isZero';
import { useActiveWeb3React } from '../../hooks';
import { isMobile } from 'react-device-detect';

const KlineChartBox = styled.div<{ mobile?: boolean }>`
    border: 1px solid gray;
    width: 100%;
    height: 430px;
    margin-right: ${props => (props.mobile ? '0' : '30px')};;
    border-radius: 12px;
    overflow: hidden;
    background: #0000003e;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`
function KlineChart({currencies}:{currencies:{[field in Field]?: Currency}}) {
    const v2FactoryContract = useV2FactoryContract();
    const [poolAddress, setPoolAddress] = React.useState<string>('');
    const { chainId } = useActiveWeb3React();
    const isFirst = useRef<boolean>(false);

    useEffect(()=>{
        isFirst.current = true;
    },[])

    useEffect(()=>{
        console.log('kline currencies==>',currencies);
        if(isFirst.current){
            isFirst.current = false
            return;
        }
        if(!currencies['INPUT'] || !currencies['OUTPUT']) {
            setPoolAddress('');
            return;
        };
        if(currencies['INPUT']?.symbol === 'USDC'){
            (currencies['INPUT'] as any).address = WETH[chainId || 42766].address;
        }else if(currencies['OUTPUT']?.symbol === 'USDC'){
            (currencies['OUTPUT'] as any).address =  WETH[chainId || 42766].address;
        }
        v2FactoryContract?.getPair((currencies['INPUT'] as any).address,(currencies['OUTPUT'] as any).address).then((v:any)=>{
            console.log('kline pool==>',v);
            setPoolAddress(v);
        }).catch((e:any)=>{
            console.log('kline e==>',e);
        })
    },[currencies['INPUT'],currencies['OUTPUT']]) // eslint-disable-line

    return (
        <KlineChartBox mobile={isMobile}>
            {
                isZero(poolAddress) ? 
                <div>This pair doesn't exist.</div> :
                poolAddress ?
                <iframe 
                height="100%" 
                width="100%" 
                id="geckoterminal-embed" 
                title="GeckoTerminal Embed" 
                src={`https://www.geckoterminal.com/zkfair/pools/${poolAddress}?embed=1&info=0&swaps=0`} 
                frameBorder="0" 
                allow="clipboard-write" 
                allowFullScreen /> : 
                !Boolean(currencies['INPUT']) || !Boolean(currencies['OUTPUT']) ? 
                <div>A token not selected</div> : <div>Loading...</div>
            }
        </KlineChartBox>
    )
}

export default React.memo(KlineChart);