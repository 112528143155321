import React, { useRef } from 'react'

import arrDown from '../../assets/svg/arr_down.svg'
import styled from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import useToggle from '../../hooks/useToggle'
import { isMobile } from 'react-device-detect'
import { useActiveWeb3React } from '../../hooks'
import { LightCard } from '../Card'
import { NETWORK_LABELS, NETWORK_ICONS } from '../../constants'
import { SupportedChainIds,UnSupportedChainIds } from '../../connectors'
import { switchNetwork } from './util'
import { ChainId } from '@sideswap/zkfair-mainnet-sdk'

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 8.125rem;
  background-color: ${({ theme }) => (!isMobile ? theme.bg1 : theme.bg3)};
  border: solid 1px  ${({ theme }) => (!isMobile ? theme.bg3 : theme.bg3)};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  left: 1.2rem;
  z-index: 100;
`

const MenuItem = styled.div`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  white-space: nowrap;
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

const Icon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-right: 8px;
  vertical-align: middle;
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
  cursor: pointer;
`

const NetworkCard = styled(LightCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`
export default function Menu() {
  const node = useRef<HTMLDivElement>()
  const [open, toggle] = useToggle(false)
  const { chainId, account } = useActiveWeb3React()
  useOnClickOutside(node, open ? toggle : undefined)

  const handleSwitchChain = (chainId: number) => {
    console.log('handleSwitchChain', chainId)
    switchNetwork(chainId)
    toggle()
  }

  function moveFirstElementToLast(arr: any[]) {
    if (arr.length < 2) {
      return arr;
    }
    const firstElement = arr.shift();
    arr.push(firstElement!);
    return arr;
  }

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <TestnetWrapper onClick={toggle}>
        {!isMobile && account && chainId && NETWORK_LABELS[chainId] ? (
          <NetworkCard>
            <Icon
              src={NETWORK_ICONS[Number(chainId) as ChainId]}
              style={{
                width: '21px',
                height: '21px',
                marginRight: '4px'
              }}
            />
            <Icon src={arrDown} />
          </NetworkCard>
        ) : null}
      </TestnetWrapper>
      {open && (
        <MenuFlyout>
          {process.env.REACT_SUPPORTED_CHAIN_ID}
          {/* TODO */}
          {moveFirstElementToLast(SupportedChainIds)
          // .sort((a,b)=>(networkConf[a as ChainId]).chainName.localeCompare(networkConf[b as ChainId].chainName))
          .map(chainId => (
            <MenuItem key={chainId} onClick={() => UnSupportedChainIds[chainId] ? window.location.href=UnSupportedChainIds[chainId] : handleSwitchChain(chainId)}>
              <Icon src={NETWORK_ICONS[chainId as ChainId]} />
              {NETWORK_LABELS[chainId as ChainId]}
            </MenuItem>
          ))}
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
